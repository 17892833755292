.SerItem1{ 
  background-color: white;
  height: 420px;
  width: 32%;
  border-radius: 10px;
  box-shadow: 0 0 7px -4px #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SerItem2{ 
    color: white;
    background-image: linear-gradient(.40turn,#000000, #EDA24F);
    height: 420px;
    width: 32%;
    border-radius: 10px;
    box-shadow: 0 0 7px -4px #000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
.Ser_item-link{
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 95%;
}
.Ser_item_info{

}
.Sertablet2{

}
.Ser_item_pic_wrab{
margin-top: 25px;
display: flex;
align-items: center;
justify-content: center;
}
.Ser_item_img{
    width: 60px;
    height: 60px;

}
.Ser_item_text1{
    color: black;
  font-weight: 100;
  margin-top: 15px;
  text-align: center;
  font-size: 22px;
  font-weight: 600;
}
.Ser_item_desc1{
    color: black;
  font-weight: 100;
  margin-top: 10px;
  font-size: 16px;

}
.Ser_item_text2{
    color: white;
  font-weight: 100;
  margin-top: 15px;
  
  text-align: center;
  font-size: 22px;
  font-weight: 600;
}
.Ser_item_desc2{
    color: white;
  font-weight: 100;
  margin-top: 10px;
  font-size: 16px;

}

@media screen and (max-width:1025px) {
  .SerItem2{
    height: 600px;
  }
  .SerItem1{
    height: 600px;
  }
  
}
@media screen and (max-width:770px) {
  
  .SerItem1{
    width: 100%;
    margin-top: 25px;
    margin-bottom: 40px;
  }

  .SerItem2{
    width: 100%;
    margin-top: 25px;
    margin-bottom: 40px;
  }

  .Ser_item_desc1{
    font-size: 20px;
  }

  
  .Ser_item_desc2{
    font-size: 20px;
  }

}

@media screen and (max-width:430px) {

  .Ser_item_desc1{
    font-size: 15px;
  }

  
  .Ser_item_desc2{
    font-size: 15px;
  }

}