.About{
    height: 80vh;
    display: flex;
    justify-content: center;
}
.about_container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 90%;
}
.about_txt{
    flex: 1;
 
    font-family: 'Raleway';
    width: 100%;
    display: block;
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    color: #eda24f;
}

.about_head{
    font-family: 'Raleway';
    width: 100%;
    display: block;
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    color: #eda24f;
    margin-bottom: 10px;

}

.about_desc{
    font-family: 'Roboto';
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    font-size: 14px;
    color: #000;
    
}
.about_cards{
    
    flex: 4;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}
.about_cards_container{
  
    height: 80%;
    display: flex;
    flex-direction: column;
}
.vl {
    border-left: 1px solid gray;
    height: 50px;
  }
.first_row_carts{
    
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

}
.second_row_carts{
    
    display: flex;
   
    justify-content: center;
    width: 100%;
    height: 100%;
    

}

@media screen and (max-width:1080px) {
    .About{
        height: 80vh;
        position: relative;

    }
    .about_cards_container{
        flex-direction: column;
    }
    .about_txt{
    position: absolute;
    top: 0;
    left: 0px;
    width: 100%;
    /* align-content: center; */
    text-align: center;
    margin-top: 25px; 
    }
    .about_desc{
        display: none;
    }
    hr{
        display: none;
    }
    .first_row_carts{
        
    }
    .second_row_carts{
        
    }
}


@media screen and (max-width:321px) {
    .About{
        height: 80vh;
        position: relative;


    }
    .about_cards_container{
        flex-direction: column;
    }
    .about_txt{   
        position: absolute;
        top: 0;
        left: 0px;
        width: 100%;
        font-size: 15px;
        /* align-content: center; */
        text-align: center;
     
    }
    .about_head{
        font-size: 16px;
    }
    .about_desc{
        display: none;
    }
    hr{
        display: none;
    }
}



@media screen and (max-width:426px) {
    .About{
        height: 80vh;
        position: relative;


    }
    .about_cards_container{
        flex-direction: column;
    }
    .about_txt{   
        position: absolute;
         top: 0;
        left: 0;
        width: 100%;
        /* align-content: center; */
        text-align: center;
     
    }
    .about_head{    font-size: 16px;
    }
    .about_desc{
        display: none;
    }
    hr{
        display: none;
    }
}