.partner{

    height: 48vh;
    display: flex;
    justify-content: center;
}
.par_con{
    display: flex;
    width: 90%;
    height: 100%;
    background-color: white;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.par_head{
    color: #EDA24F;
    margin-top: 45px;
    font-weight: 100;
    margin-bottom: 25px;
}
.par_carsouel{
font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.xxxx{
    cursor: pointer;
}