.card_item {
  background-color: white;
  height: 65%;
  overflow: auto;
  width: 24%;
  border-radius: 10px;
  box-shadow: 0 0 15px -8px #000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
}

.card_item:hover {
  background-color: white;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  transform: scale(1.05);
}

.card_item-link {
  display: flex;
  flex-direction: column;
  width: 88%;
  height: 95%;
}
.card_item_text {
  color: black;
  font-weight: 100;
  margin-top: 25px;
  font-size: 1.5rem;
}
ul {
  list-style-type: none;
}
h3 {
  color: #eda24f;
  margin-bottom: 10px;
  font-weight: 100;
}
.info {
  margin-top: 15px;
}
.info2 {
  margin-top: 50px;
  margin-bottom: 1.5rem;
}
li {
  margin-top: 8px;
}

@media screen and (max-width: 960px) {
  .card_item {
    background-color: white;
    width: 240%;
    border-radius: 10px;
    box-shadow: 0 0 15px -8px #000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
  .card_item-link {
    display: flex;
    flex-direction: column;
    width: 89%;
    height: 100%;
  }
  .tablet2 {
    display: flex;
    justify-content: center;
    margin-right: 45px;
  }
  .card_item_pic_wrab {
    padding: 20px;
  }
  .tablet {
    display: flex;
    margin-bottom: 20px;
  }
  .info {
    flex: 1;
  }
  .info2 {
    flex: 1;
    margin-top: 14px;
  }
}
@media screen and (max-width: 1025px) {
  .card_item_text {
    font-size: 21px;
  }
}

@media screen and (max-width: 426px) {
  .card_item {
    background-color: white;
    width: 149%;
    border-radius: 10px;
    box-shadow: 0 0 15px -8px #000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 376px) {
  .card_item {
    background-color: white;
    width: 132%;
    border-radius: 10px;
    box-shadow: 0 0 15px -8px #000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 321px) {
  .card_item {
    background-color: white;
    width: 115%;
    border-radius: 10px;
    box-shadow: 0 0 15px -8px #000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 63px;
  }
  .card_item_text {
    color: black;
    font-weight: 100;
    margin-top: 25px;
    font-size: 24px;
  }

  li {
    font-size: 12px;
  }
}
