.soltions{
    background-color: #f8f8f8;
    display: flex;
    justify-content: center;
    height: fit-content;
}
.solutionContainer{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    
}
.solution_bg{
    height: 100px;
    background-color: #eda24f;
    display: flex;
    align-items: center;
    justify-content: center;
}
.solution_bg_container{

    width: 85%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.solution_left{
    flex: 3;
    width: fit-content;
    width: fit-content;
    display: flex;
    justify-content: flex-start;
   
}
.solution_left>p{
    font-size: 25px;
    color: white;
    width: fit-content;
    
}
.solution_right{
  
    font-size: 22px;
    color: white;
    flex: 1;
    width: fit-content;
    display: flex;
    justify-content: flex-end;
   
}
.solution_desc{
    display: flex;
    align-items: center;
    justify-content: center;

}
.solution_desc_conatianer{
    
    width: 85%;
    height: 100%;
    display: flex;
    flex-direction: column;
    
    justify-content: center;

}
.SDESC{
    color: black;
    font-size: 22px;
    margin-top: 80px;
}
.learnMore{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
    margin-bottom: 20px;
}
.learnMore>a{
    color: gray;
    font-size: 20px;
    text-decoration: underline;
    cursor: pointer;
}
