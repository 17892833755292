.toggle-btn {
  width: 55px;
  height: 30px;
  /* background: #f1b777; */
  background: #fff;
  color: #000;
  font-weight: bold;
}

.Mui-selected {
  background: #f1b777 !important;
}

.form-header {
  font-size: 18px;
}

.divider {
  width: 100%;
  margin: auto;
  height: 20px;
  background-color: #f1b7773d;
  margin-top: 10px;
}

.input-wrap {
  width: 40%;
}

.form-control {
  background: #ffffff;
  border: 1px solid #f1b777;
  padding-left: 10px;
}

.input-field[type="file"] {
  padding-left: 0;
}

.submit-btn {
  /* background: #f1b777; */
  border: 1px solid #000000;
  border-radius: 10px;
  width: 160px;
  height: 40px;
}

.submit-btn:hover {
  background: #f1b777;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.form-wrap {
  gap: 6rem;
}
