.drop{
    background-color: white;
    border-radius: 10px ;
    width: 355px;
    height: fit-content;
    transition: 0.3s ease-in-out;
    position: absolute;
    top:66px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 13;
}
.drop_container{
    height: 95%;
    width: 100%;
}
.dropitem{
    display: flex;

    align-items: center;
    margin-top: 15px;
    border-bottom: 1px solid gray;
    font-weight: 600;
    position: relative;

}
.drop_links{
 color: black;
 font-size: 13px;
  display: flex;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  margin-bottom: 15px;
  transition: 0.3s ease-in-out;
}
.drop_links:hover{
    color: #EDA24F;
    transition: 0.3s ease-in-out;
}
/***************************************************************/

.bg{
    clip-path: polygon(0% 0%, 35% 0, 100% 0, 57% 38%, 0% 100%);
    background-color: white;
    width: 20%;
    height: 20%;
    position: absolute;
  top: 0px;
  left: 140px;
  z-index: -1;
}
.sidedrop{
    background-color: white;
    width: 155px;
    height: fit-content;
    transition: 0.3s ease-in-out;
    box-shadow: 1px 1px 1px gray;
    position: absolute;
    z-index: -1;
    left: -155px;
    top: -6px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sidedrop_container{
    height: 95%;
    width: 100%;
}
.sidedropitem{
    border-bottom: 1px solid gray;
}