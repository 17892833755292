.C_Item{
background-color: white;
  height: 18%;
  width: 85%;
  border-radius: 16px;
  box-shadow: 0 0 15px -8px #000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 45px;
}
.C_Item_container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.links{
    text-decoration: none;
    cursor: pointer;

}
.C_Item_info{
    flex:2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}
.C_Item_desc{
    width: 70%;
    font-size: 16px;
letter-spacing: 0px;
color: #000000;
text-transform: lowercase;
margin-left: 40px;
margin-top: 5px;
}
.C_Item_header{
    margin-top: 25px;
    margin-left: 40px;
    text-align: left;
font-size: 25px;
letter-spacing: 0px;
color: #000000;
text-transform: uppercase;

}
.C_Item_icons{
    flex: 1;
    
    display: flex;
    justify-content: center;
}

.detials{
    flex: 1;
    display: flex;
    align-items: center;
    color: gray;

}
.detials>span{
    margin-left: 41px;
}
.CIicon{
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-right: 80px;


}
.CIBU{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EDA24F;
    color: white;
    border: none;
    padding: 5px;
    height: 35px;
    width: 100px;
    font-size: 12px;
    font-weight: 400;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
}

@media screen and (max-width:1025px) {
   
    .C_Item_header{
        font-size: 23px;

    }
    .C_Item_desc{
        font-size: 12px;
    }
    .detials {
        flex: 2 1;}
     
 }

 @media screen and (max-width:790px) {
   
    .C_Item_header{
        font-size: 18px;

    }
    .C_Item_desc{
        font-size: 10px;
    }
    .detials {
        flex: 2 1;
    font-size: 12px;}
     
 }
@media screen and (max-width:490px) {
    .C_Item{
        height: 20%;
        width: 95%;
        flex-direction: column;
    }
    .C_Item_info{
        align-items: center;
    }
    .C_Item_desc{
     
        font-size: 11px;
        width: 90%;
        margin-right: 35px;
    }
    .C_Item_header{
        font-size: 12px;
        width: 100%;
        margin-bottom: 8px;
    
    }
    .C_Item_icons{
        flex-direction: column;
    }
    .detials>span{
        font-size: 12px;
        margin-left: 32px;
    }
     .CIicon{
         display: flex;
         align-items: center;
         justify-content: center;
         margin-bottom: 6px;
         
     }
     .CIBU{
         margin-left: 65px;
         padding: 5px;
    height: 25px;
    width: 80px;
    font-size: 10px;

     }
 }
 @media screen and (max-width:380px) {
    .carrer_text {
        font-size: 44px;
    }
     
 }