.why{
    background-image: url('../images/slide2/Mask\ Group\ 12@2x.webp');
    background-position: center;
    background-size: cover;
    height: 100vh;
    display: flex;
    justify-content: center;
}
.why_con{
   
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95%;
    height: 100%;
}
.why_txt{
    flex: 1;
    
    height: 100%;
    font-family: 'Raleway';
    font-size: 45px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    color: #eda24f;
    
    display: flex;
    align-items: center;
    justify-content: center;
}
.why_cards{
    
    flex: 5;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin-top: 3rem; */
}
.question{
    font-family: 'Raleway';
    font-size: 45px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    color: #eda24f;
    display: block;
}
@media screen and (max-width: 960px) {
    .why{
        height: 226vh;
    }
    .why_txt{
        flex: 1;
    }
    .why_cards{
        flex: 9;
    }
    .why_con{
        flex-direction: column;
    }
    .why_cards{
        flex-direction: column;
        height: 9px;
        width: 254px;
    }
    .why_txt{
        margin-top: 21px;
    margin-bottom: 33px;
    }
    
}

@media screen and (max-width:426px) {
    .why{
    height: 238vh;
}
  }


  @media screen and (max-width:376px) {
    .why{
    height: 238vh;
}
  }