.navbar{

    background-color: white;
    height: 13vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;

    top: 0;
    z-index: 999;
    box-shadow: -2px 4px 39px rgba(0, 0, 0, 0.25);
    padding-block: 0;
}
.navber_contianer{
    display: flex;
    justify-content: center;
    /* align-items: center; */
    height: 80px;
    max-width: 1500px;
    ;

}
.bt{
    margin-top: 0px;
}
.navbar_Logo{
  color: black;
  margin-right: 50px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;

}
.navbar_ICon{
    margin-left: 0.5rem;
  font-size: 1.8rem;
}
.nav_menu{
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 80vw;
    margin-left: 30px;


}
.dropdwonbutton{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  transition: all 0.2s ease-in-out;
}

.nav_item{
    height: 80px;
    display: flex;
    justify-content: flex-start;
}
.nav_links{
    color: black;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;

}
.nav_links>p{
letter-spacing: 0px;
color: black;
text-transform: uppercase;
font-weight: 100;
transition: 0.3s ease-in-out;
}
.nav_links>p:hover{
  color:  #EDA24F ;
  transition: 0.3s ease-in-out;
}
.MENU_ICON{
    color: black;
}
.nav_links_mobile{
    display: none;
}
.menu_icon{
    display: none;
}

@media screen and (max-width: 1080px){

    .NavbarItems {
        position: relative;
      }

      .nav_menu{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 80px;
        left: -200%;
        opacity: 1;
        transition: all 0.5s ease;
      }

      .nav_menu.active{
        background: white;
        left:  -250px;
        top: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        height: 100vh;
        justify-content: center;
      }
      .nav_links{
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
        margin-left: 0px;
        color: #242424;
      }
      .nav_links:hover{
        background-color: #242424;
        color: #242424;
        border-radius: 0;
      }
      .navbar_Logo{
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%, 50%);
        height: 70px;
      }
      .menu_icon{
        display: block;
        position: absolute;
        top: 0;
        right:0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        z-index: 2;
      }

      .menu_icon>.MuiSvgIcon-root {
        font-size: 55px;

      }
      .CLOSE_ICON{
        color: #242424;
        font-size: 2rem;
      }
      .nav_links_mobile{
        display: block;
        text-align: center;
        margin: 2rem auto;
        border-radius: 4px;
        width: 30%;
        text-decoration: none;
        font-size: 1.5rem;
        background-color: transparent;
        color: #fff;
        padding: 14px 20px;
        border: 1px solid #fff;
        transition: all 0.3s ease-out;
      }
      .nav_links_mobile:hover{
        background: #fff;
        color: #242424;
        transition: 250ms
      }
}

@media screen and (max-width: 426px) {
  .menu_icon>.MuiSvgIcon-root {
    font-size: 32px;
    margin-top: 14px;

  }
  .navbar_Logo > img{
    height: 17px;

  }
}



@media screen and (max-width: 769px) {
  .navber_contianer{
    width: 100%;

  }
  .navbar_Logo{    margin-left: 44px;
    /* margin-bottom: 22px; */
    margin-top: -26px;
  }
  .menu_icon{
    margin-top: -15px;
  }

}