.footer{
    height: auto;
    background:url('../images/footer.png');
    background-position: center;
    background-size: contain;
    color: white;
}
.Footer_COntianer{
    display: flex;
    justify-content: space-between;
}
.f1{
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 65px;
}
.f1_logo{
    margin-left: 65px;
    
}
.f1_logo_info{
    margin-left: 65px;
    font-size: 20px;   
    letter-spacing: 0px;
    color: #e9e8e8;
    margin-top: 15px;
}
.f1_logos{
    margin-left: 65px;
    margin-top: 150px;
}
.f1_logos>a{
    color: #EDA24F;
    text-decoration: none;
    margin-right: 10px;
    transition: all 0.2s ease-in-out;
}
.res{
    width: 100px;
    height: 100px;
}

.f1_logos>a :hover{
    color: #e9e8e8;
    cursor: pointer;
   transition: all 0.2s ease-in-out;
}

.f2{
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 85px;
}
.f2_header{
    color: #EDA24F;
}
.f2_info{
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.f2_p{
    color:#e9e8e8;
    transition: all 0.2s ease-in-out;

}
.f2_p:hover{
    color: #EDA24F;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

}
.f2_info_container{
    margin-top: 40px;
}
.f2_2022{
    margin-top: 130px;
}
.f2_2022>p{
    font-size: 12px;
}
.f3{
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 85px;
    display: none;
}
.f3_header{
    color: #e9e8e8;
}
.f3_info_container{
    
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    
}
.f3_txt>p{
    margin-top: 15px;
}
.xx{
    margin-top: 30px;
}
.mapa{
    width: 200px;
    height: 100px;
}
.f4{
    display: flex;
    flex-direction: column;
    margin-top: 85px;
    margin-right: 65px;
}

@media screen and (max-width: 960px){
    
    .footer{
        height: 221vh;
    }
    .Footer_COntianer{
        flex-direction: column;
        align-items: center;
    }
    .f1_logos{
        margin-left: 154px;
        margin-top: 75px;
    }
    .f1_logo_info>p{
        font-size: 40px;
    }

    .res{
        width: 300px;
        height: 300px;
        margin-right: 40px;
    }
    .f2_2022{
       display: none;
    }
    .f2_header{
        font-size: 45px;
    }
    .f2{
        margin-right: 40px;
    }
    .f4{
        margin-left: 65px;
    }
}

@media screen and (max-width:426px) {
    
    .footer{
        height: 225vh;
    }
    .f1_logo_info{
        font-size: 16px;
        margin-right: 25px;
    }
    .res{
        margin-right: 45px;
        width: 200px;
        height: 200px;
    }
    .f1_logos{
        margin-left: 112px;
        margin-top: 75px;
    }
    
}


@media screen and (max-width:378px) {
    
    
    .f1_logos{
        margin-left: 95px;
        margin-top: 75px;
    }
    
}
@media screen and (max-width:321px) {
    
    .f1_logo_info{
        font-size: 16px;
        margin-right: 25px;
    }
    .res{
        margin-right: 45px;
        width: 200px;
        height: 200px;
    }
    .f1_logos{
        margin-left: 75px;
        margin-top: 75px;
    }
    .footer{
        height: 228vh;
    }
    .f3_txt{
        font-size: 11px;
    }
    .f4{
        margin-right: 80px;
    }
    .f2_header{
        font-size: 30px;
        margin-left: 20px;
    }

    
}