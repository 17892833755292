.About_card_item{
    height: 90%;
    width: 40%;
    display: flex;
    margin-left: 10px;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
}

.About_Cart_item_link{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 88%;
    height: 95%;
}
.About_card_item_pic_wrab{
    display: flex;
    justify-content: center;
}
.About_Cart_txt{
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #524f4f;
    margin-top: 15px;
    display: block;
}
@media screen and (max-width:426px){
    .About_card_item{
        width: 100%;

    }
    .About_Cart_txt{
        box-sizing: border-box;
        font-size: 9px;
    }

}