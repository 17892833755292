.services{
    display: flex;
    flex-direction: column;
    justify-content: center;

}
.ser_bg{
    height: 500px;
    background:url('../images/servicebanner/Group-5161.webp');
    background-position: center;
    background-size: cover;
    position: relative;    
}

.ser_text{
    font-size: 45px;
    font-family:'Inter', sans-serif;
    font-weight: 500;
    letter-spacing: 1px;
    color: #FFFFFF;
    text-transform: uppercase;
    position: absolute;
    top: 78px;
    left: 140px;
}
.ser_desc{
    width: 700px;
    font-size: 22px;
    font-family:'Inter', sans-serif;
    font-weight: 200;
    letter-spacing: 1px;
    color: #FFFFFF;
    text-transform: uppercase;
    position: absolute;
    top: 160px;
    left: 140px;
}
.ser_button1{
    
    width: 150px;
    height: 50px;
    padding: 10px;
    border-radius: 10px;
    font-size: 16px;
    border: none;
    position: absolute;
    background-color: rgba(254,202,0,255);
    top: 360px;
    left: 140px;
    transition: all 0.3s ease-in-out;
    outline: none;
    cursor: pointer;
}

.ser_button2{ 
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    width: 150px;
    height: 50px;
    padding: 10px;
    border-radius: 10px;
    outline: none;
    font-size: 16px;
    color: rgba(254,202,0,255);;
    border: none;
    position: absolute;
    background-color: white;
    top: 360px;
    left: 140px;
}
.ser1{
    background: #F8F8F8;
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.ser2{
    background: white;
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}
.headerrr{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.ser_header{
    width: fit-content;
    margin-top: 50px;
}
.ser1_container{
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.link{
    text-decoration: overline;
    color: black;
}

@media screen and (max-width:770px) {
    .ser_header{font-size: 24px;}
    .ser_desc{
        width: 610px;
    }
    .ser1{
        height: 228vh;
        
    }
    .ser1_container{
        flex-direction: column;
        justify-content: center;
        height: 96%;
    }

    
    .ser2{
        height: 227vh;
       
    }
    .ser2_container{
        flex-direction: column;
        justify-content: center;
    }
    .headerrr{
        margin-top: 17px;
       
    }
    
}


@media screen and (max-width:430px) {
    .ser_header{ font-size: 15px;}
    .ser_desc{
        width: 270px;
        font-size: 14px;
    }
    .ser_text{
        font-size: 30px;
    }
    .ser_bg{
        margin-left: -44px;
    }
    .ser1{
        height: 225vh;
        
    }
    .ser1_container{
        flex-direction: column;
        justify-content: center;
    }

    
    .ser2{
        height: 225vh;
       
    }
    .ser2_container{
        flex-direction: column;
        justify-content: center;
    }
    .headerrr{
        margin-top: 17px;
    }
    
}
@media screen and (max-width:380px) {
    .ser1{height: 239vh;}
    .ser2{height: 239vh;}
    .ser_header{font-size: 13px;}
}