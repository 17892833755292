.career{
    height: fit-content;
    
}
.career_bg{
    height: 450px;
    background:url('../images/careerbanner/Group-5162.webp');
    background-position: center;
    background-size: cover;
    position: relative;

}
.carrer_text{
    font-size: 45px;
    font-family:'Inter', sans-serif;
    font-weight: 800;
    letter-spacing: 1px;
    color: #FFFFFF;
    text-transform: uppercase;
    position: absolute;
    top: 78px;
    left: 140px;
}
.career_desc{
    width: 700px;
    font-size: 22px;
    font-family:'Inter', sans-serif;
    font-weight: 100;
    letter-spacing: 1px;
    color: #FFFFFF;
    text-transform: uppercase;
    position: absolute;
    top: 140px;
    left: 140px;

}
.jobs{
    background-color: #F8F8F8;
    height: 140vh;
    display: flex;
    justify-content: center;
}
.jobs_container{
    width: 95%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
   
}

   
@media screen and (max-width:770px) {
   
   .career_desc{  
      font-size: 16px;
      width: 70%;
   }
    
}
@media screen and (max-width:490px) {
    .career_bg{
        text-align: center;
        margin-left: -109px;
    }
    .carrer_text{
        font-size: 50px;
        
    }
   
    .career_desc{
        font-size: 14px;
        width: 67%;
        margin-right: 55px;
    }
     
 }