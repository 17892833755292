.contact{
    background-color: #F8F8F8;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

}
.conatact_container{
    width: 95%;
    height: 100%;
    display: flex;

}
.conact_form{
    flex: 7;
    display: flex;

    flex-direction: column;
    justify-content: center;

}
.cxcx{

    width: 300px;
    display: flex;
    justify-content: start;
}
.conatct_loctaions{
    flex: 8;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 11vh;
    /* margin-bottom: 100px; */
}
.contact1{
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 85px;
    display: none;
}

.contact2{
    display: flex;
    flex-direction: column;
    margin-top: 85px;
}
.contact_header{
    color: black;

}
.contact_info_container{

    display: flex;
    flex-direction: column;
    margin-top: 15px;

}
.conatact_infoo{

}
.con_txt{
    display: flex;
    align-items: center;
    margin-bottom: 25px;

}
.con_txt>p{
    margin-left: 15px;
    font-family: 'Roboto';
    font-size: 15px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: normal;
    color: #000;
}
.con_xx{


}
.con_mapa{
    border: 2px solid white;
    box-shadow: 0 0 2px -1px #000;

    border-radius: 10px;
    width: 300px;
    height: 150px;
}


form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cinput {
    width: 50%;
    height: 50px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #726D6D29;
    border-radius: 5px;
    margin: 4px 0px;
    box-shadow: 0 0 15px -8px #000;
    font-size: 14px;
    padding-left: 10px;
  }

  .ctextarea {
    box-shadow: 0 0 15px -8px #000;
    width: 50%;
    margin: 10px 0px;
    font-size: 14px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #726D6D29;
    border-radius: 5px;
    padding:20px
  }

  .button1{
    width: 50%;
    height: 50px;
    /* UI Properties */
    background: #000000 ;
    opacity: 1;
    border: none;
    border-radius: 8px;
    font-size: 20px;
    box-shadow: 0 0 15px -8px #000;
    color: white;
  }
  .CONTACTFORMHEADER{
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #000000;
    text-transform: uppercase;

  }

  .cc{
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50%;
  }
  .cc1{
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      flex-direction: column;


  }
  .cc2{
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      transition: 0.4s ease-in-out;

  }
  .cc2:hover .cctxt {
    color: #EDA24F;
    transition: 0.4s ease-in-out;

  }
  .cc2:hover .ccicon {
    transition: 0.9s ease-in-out;
    transform: rotate(360deg);

  }


  .cc1:hover .cctxt {
    color: #EDA24F;
    transition: 0.4s ease-in-out;

  }
  .cc1:hover .ccicon {
    transition: 0.9s ease-in-out;
    transform: rotate(360deg);

  }
  .cctxt{
    text-decoration: none;
    margin-top: 20px;
    font-size: 25px;
    font-family: 'Roboto';
    color: gray;
  }
  .ccicon{
      color: #EDA24F;
      transition: 0.4s ease-in-out;
  }

  @media screen and (max-width:769px) {
      .contact{
          height: fit-content;

      }
      .conatact_container{

          flex-direction: column;
          margin-top: 50px;
      }


  .cinput {
    width: 80%;
    height: 50px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #726D6D29;
    border-radius: 5px;
    margin: 4px 0px;
    box-shadow: 0 0 15px -8px #000;
    font-size: 14px;
    padding-left: 10px;
  }

  textarea {
    box-shadow: 0 0 15px -8px #000;
    width: 80%;
    margin: 10px 0px;
    font-size: 14px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #726D6D29;
    border-radius: 5px;
    padding:20px
  }

  .button1{
      margin-right: 368px;
      margin-top: 25px;
      width: 30%;
  }
  }



  @media screen and (max-width:426px) {
    .contact{
        height: fit-content;

    }
    .conatact_container{

        flex-direction: column;
        margin-top: 50px;
    }.conatct_loctaions{
        flex-direction: column;
    }

  .button1{
    margin-right: 198px;
    margin-top: 15px;
    width: 30%;
}
   .cc{
       margin-top: 25px;
   }
}


@media screen and (max-width:376px) {
    .contact{
        height: fit-content;

    }
    .conatact_container{

        flex-direction: column;
        margin-top: 50px;
    }.conatct_loctaions{
        flex-direction: column;
    }

  .button1{
    margin-right: 179px;
    margin-top: 12px;
    width: 30%;
}

}