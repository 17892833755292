* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
  }
  @import url('https://rsms.me/inter/inter.css');
html { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  html { font-family: 'Inter var', sans-serif; }
}
@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Lato&family=Montserrat&family=Raleway&display=swap');
.App{
  font-family: 'Montserrat';
}
[data-reach-menu-button] {
  padding: 0.75em 1em;
  background-color: #fafafa;
  border: none;
  border: 2px solid #ccc;
  border-radius: 5px;
  margin: 5px;
}
[data-reach-menu-button][aria-expanded="true"] {
  border-radius: 5px 5px 0 0;
}

[data-reach-menu-list] {
  border-radius: 0 0 5px 5px;
  border: 2px solid #ccc;
  background-color: white;
  transform: translateY(-2px);
}

.w-60 {
  width: 63% !important;
}